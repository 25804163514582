import * as React from "react";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import AirplanemodeActiveRoundedIcon from "@mui/icons-material/AirplanemodeActiveRounded";
import Button from '@mui/material/Button';
import './Result.css';

import { useState, useEffect, useRef } from "react";


var axios = require("axios");
// import Fares from './Fares';

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const FlightsResults = (props) => {
  
  const [MergedWithFaresFinall, setMergedWithFaresFinall] = useState([]);
  const [RoundTripMergedWithFares, setRoundTripMergedWithFares] = useState([]);
  const [arrayResult, setarryResult] = useState([]);
  const [currency_code, setCurrency_code] = useState([]);
  const [filtercurrency, setFiltercurrency] = useState([]);
  const [Loader, setLoader] = useState(60);
  const [BoolforLoading, setBoolforLoading] = useState(true);
  const [sortBySelected, setSortBySelected] = useState();
  const [CurrencySymbols, setCurrencySymbols] = useState([])
  const sortValue = useRef();
  let TotalResShowCheck = false;

  const results = props.results;
  // console.log(results.count);

  const sortByResultsValue = props.sortResult;

  const faresFilter = props.priceFilter;
  let valueFilterStaus = props.valueFilterStaus;

  let resultFares = results.fares;

  let resultLegs = results.legs;
  //console.log('resultLegs.length', resultLegs.length, 'resultFares.length', resultFares.length, 'results.faresCount', results.faresCount);
  let countObj = [];
  let countObjFares = [];
  let resultTrips = results.trips;
  let resultFilters = results.filters;
  var sumcountAvr = 0;
  // console.log("resultLegs", resultLegs);
  // console.log("resultTrips", resultTrips);
  const flightPlaces = props.flightPlaces;
  const stopsResults = props.stopsResults;
  const durationFilter = props.durationFilter;
  let checkedAirlines = props.checkedAirlines;
  let unCheckedAirlines = props.unCheckedAirlines;
  let isfilterStatus = props.isfilterStatus;
  const [isAirlineEqualToZero, setIsAirlineEqualToZero] = useState(true);
  //console.log(checkedAirlines, unCheckedAirlines, isfilterStatus);
  let direct, onestop, twostops;
  let resultLegs1 = [];
  let resultLegs2 = [];
  let resultLegs3 = [];
  let bolean = "false";
  let truefalseMergedFilterAirlines = [];
  if(sortByResultsValue === "Best"){
   // console.log("best")
   // resultLegs  = resultLegs.filter((item) => item.stopoverCode == "DIRECT");
    resultLegs =  resultLegs.filter((item) => item.stopoverCode == "DIRECT" || "ONE_STOP");
   // console.log((faresFilter[1]))
   // console.log(faresFilter[1] - (faresFilter[1] * .50));
    resultFares = resultFares.filter((fare) => fare.price.totalAmount < faresFilter[1] - (faresFilter[1] * .50));
   // resultFares = resultFares.sort((a, b) => (a.price.totalAmount > b.price.totalAmount ? 1 : -1));
    //console.log(resultFares)
   // resultLegs =  resultLegs.filter((item) => item.stopoverCode == "MORE_THAN_ONE_STOP");
  }   

  if (valueFilterStaus) {
    TotalResShowCheck = true;
    resultFares = resultFares.filter((fare) => fare.price.totalAmount >= faresFilter[0] && fare.price.totalAmount <= faresFilter[1]);
    //   console.log(resultFares)
  }
  if (stopsResults.DIRECT == true) {
    TotalResShowCheck = true;
    direct = "DIRECT";
    resultLegs1 = resultLegs.filter((item) => item.stopoverCode == direct);
  }

  if (stopsResults.ONE_STOP == true) {
    TotalResShowCheck = true;
    onestop = "ONE_STOP";
    resultLegs2 = resultLegs.filter(
      (resultLegs) => resultLegs.stopoverCode == onestop
    );
    //console.log(resultLegs);
  }

  if (stopsResults.TWO_STOPS == true) {
    TotalResShowCheck = true;
    twostops = "MORE_THAN_ONE_STOP";
    resultLegs3 = resultLegs.filter(
      (resultLegs) => resultLegs.stopoverCode == twostops
    );
  }

  if (
    stopsResults.TWO_STOPS == true ||
    stopsResults.ONE_STOP == true ||
    stopsResults.DIRECT == true
  ) {
    // TotalResShow = resultLegs.length;
    TotalResShowCheck = true;
    resultLegs = [...resultLegs1, ...resultLegs2, ...resultLegs3];
  } else {
    //  TotalResShow = 20;
    TotalResShowCheck = false;
    resultLegs = resultLegs;
  }
  resultLegs = resultLegs.filter(
    (leg) =>
      leg.durationMinutes >= durationFilter[0] &&
      leg.durationMinutes <= durationFilter[1]
  );
  //console.log(resultLegs)
  //console.log(direct, onestop, twostops)
  if (isfilterStatus) {
    // console.log("hello its activated")
    const isSameUser = (a, b) => a.code === b.code && a.name === b.name;
    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left, right, compareFunction) =>
      left.filter(
        (leftValue) =>
          !right.some((rightValue) => compareFunction(leftValue, rightValue))
      );

    const onlyInA = onlyInLeft(checkedAirlines, unCheckedAirlines, isSameUser);
    const onlyInB = onlyInLeft(unCheckedAirlines, checkedAirlines, isSameUser);
    truefalseMergedFilterAirlines = [...onlyInA, ...onlyInB];
    if (truefalseMergedFilterAirlines.length === 0) {
      props.passAirlinefilterlengthStatus(false);
    } else {
      props.passAirlinefilterlengthStatus(true);
    }
    if (truefalseMergedFilterAirlines.length >= 1) {
      // TotalResShow = resultLegs.length;
      TotalResShowCheck = true;
      // resultLegs = resultLegs.filter(leg => leg.airlineCodes === truefalseMergedFilterAirlines.code)
      resultLegs = resultLegs.filter((x) =>
        truefalseMergedFilterAirlines.some((y) => {
          if (x.airlineCodes[0] === y.code) {
            // console.log(x)
            // console.log(y)
            // x = JSON.parse(JSON.stringify(x));
            // y = JSON.parse(JSON.stringify(y));
            // return {...x,...y}

            let checkRes = Object.assign(x, y);
            //  console.log("NewObj insideIfCondition => ", NewObj)

            return checkRes;
          }
        })
      );
    } else {
      resultLegs = resultLegs;
      //   TotalResShow = 20;
      TotalResShowCheck = false;
    }
  }
 
   const getSumbolFunction = async () => {
    try {
      const response = await fetch('/currencySymbols.json');
      const data = await response.json();
      // console.log(data);
      setCurrencySymbols(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(()=> {
    getSumbolFunction();
  },[]);
  //console.log(resultLegs)
  // console.log(truefalseMergedFilterAirlines)
  // if(truefalseMergedFilterAirlines.length === 0){
  //   setIsAirlineEqualToZero(false)
  // }
  // if(isAirlineEqualToZero === false){
  //   props.isAirlineEqualToZeroResult(isAirlineEqualToZero);
  // }
  // console.log(flightPlaces)
  let from = flightPlaces.from;
  let to = flightPlaces.to;
  let triptype = flightPlaces.triptype;
  //console.log(triptype)
  let deal;
  let filteredcurrencyCodeP;
  let currencysymbolpPassed;
  // var loopcount = 0;
  // // Function to calculate the average of numbers
  // function avg(resultTrips, resultFares) {
  //   var sum = 0;

  //   // Iterate the elements of the array
  //   resultTrips.forEach(function (trip, idxT) {
  //     resultFares.forEach(function (fare, idxF) {
  //       if(trip.id === fare.tripId){
  //         sum += fare.price.totalAmount ;
  //       }
  //       loopcount = sum / resultFares.length;
  //     });
  //   });

  //   // Returning the average of the numbers
  //   return sum / resultFares.length;
  // }
  // avg(resultTrips, resultFares);
  //console.log(loopcount)

  // useEffect(async (props) => {
  // const currencyCode = await axios.get("http://localhost:3000/Common-Currency.json");
  // setCurrency_code(currencyCode)
  // console.log(currencyCode);

  // setCurrency_code(currencyCode);
  // if (triptype == 'roundtrip') {
  //  } else {
  //alert(triptype)
  // var legsTripsMerged = resultLegs.filter(x => resultTrips.some(y => {
  //   if (x.id == y.legIds[0]) {
  //     // console.log(x)
  //     // console.log(y)
  //     // x = JSON.parse(JSON.stringify(x));
  //     // y = JSON.parse(JSON.stringify(y));
  //     // return {...x,...y}

  //     let NewObj = Object.assign(x, y)
  //     //  console.log("NewObj insideIfCondition => ", NewObj)

  //     return NewObj
  //   }
  // }));

  // result banthu finally
  // console.log(legsTripsMerged)

  // var MergedWithFares = resultFares.filter(x => legsTripsMerged.some(y => {
  //   if (x.tripId == y.id) {
  //     // console.log(x)
  //     // console.log(y)
  //     // x = JSON.parse(JSON.stringify(x));
  //     // y = JSON.parse(JSON.stringify(y));
  //     // return {...x,...y}

  //     let NewObj = Object.assign(x, y)
  //     //  console.log("NewObj insideIfCondition => ", NewObj)

  //     return NewObj
  //   }
  // }));

  // console.log(MergedWithFares)
  // console.log(currencyCode.data);

  //   let currencycodeFind = MergedWithFares.filter(x => currencyCode.data.some(y => {
  //     if (x.price.currencyCode == y.code) {
  //       //  console.log(x.price)
  //       // console.log(y.symbol)

  //       // x = JSON.parse(JSON.stringify(x));
  //       // y = JSON.parse(JSON.stringify(y));
  //       // return {...x,...y}

  //       let curObj = Object.assign(x, y)
  //       //   let curObj = y.symbol;
  //       //  console.log("NewObj insideIfCondition => ", NewObj)

  //       return curObj
  //     }
  //   }));
  //  // console.log(currencycodeFind);
  //   setFiltercurrency(currencycodeFind);

  //  var cars = [{ make: 'audi', model: 'r8', year: '2012' }, { make: 'audi', model: 'rs5', year: '2013' }, { make: 'ford', model: 'mustang', year: '2012' }, { make: 'ford', model: 'fusion', year: '2015' }, { make: 'kia', model: 'optima', year: '2012' }],
  //   let arrayResult = currencycodeFind.reduce(function (r, a) {
  //     r[a.id] = r[a.id] || [];
  //     r[a.id].push(a);
  //     return r;
  //   }, Object.create(null));
  //    // console.log(arrayResult);
  //   setarryResult(arrayResult);
  //   setMergedWithFaresFinall(currencycodeFind);
  // }

  // setTimeout(() => {

  // }, 3000)

  // console.log(arrayResult['ea83e851a328cb2amsr:6E6858~6']);

  // console.log(MergedWithFaresFinall)    //  One way trip is ready and working fine => **** MergedWithFaresFinall ****

  // **************************************************
  // **************************************************
  // RoundTrip experiments starts here
  // roundTrip

  //   ends

  // },[])

  // setTimeout(() => {

  //  }, 4000)

  // console.log(MergedWithFaresFinall)
  // console.log(currency_code);
  // console.log(filtercurrency)
  // function checkingfunc() {

  //   MergedWithFaresFinall.map((fares, index) => {
  //     let groupId = fares.id;
  //     // console.log(groupId);
  //     return (

  //       arrayResult[groupId].map((item, index) => {
  //         return (
  //           console.log(item.arrivalTime + item.providerCode)
  //         )

  //       })

  //     )

  //   })

  // }
  // checkingfunc();
  //   var total = 0;
  //   var count = 0;
  //   var totalAmount = 0;

  //     let calculateAverage =  resultFares.filter(x => resultTrips.some(y => {
  //         if(x.tripId === y.id){
  //           total += x.price.totalAmount;
  //           count++
  //           console.log(total / count);

  //         }

  //         console.log(total);
  //         return totalAmount = total / count;

  //  }))
  //    console.log(totalAmount)
  useEffect(() => {
    if (TotalResShowCheck) {
      // alert(TotalResShowCheck);
      setLoader(results.count);
    } else {
      setLoader(50);
    }
  }, [TotalResShowCheck]);

  const loaderFunction = (event) => {
    setBoolforLoading(false);
    setTimeout(() => {
      setBoolforLoading(true);
      setLoader(Loader + 20);
    }, 10);
  };
  // cheapest
//   function comparator(a, b) {
    
//     if (a.dataset.indexvalue < b.dataset.indexvalue)       
//         return -1;
//     if (a.dataset.indexvalue > b.dataset.indexvalue)
//         return 1;
//     return 0;
// }
function comparatorFast(a, b) {
 // console.log(a.dataset.indexfast, b.dataset.indexfast)
  if (a.dataset.indexfast < b.dataset.indexfast)
     
      return -1;
  if (a.dataset.indexfast > b.dataset.indexfast)
      return 1;
  return 0;
}
function comparatorBest(a, b) {
  //    console.log(a.dataset.indexbest, b.dataset.indexbest)
  if (a.dataset.indexbest < b.dataset.indexbest)
     
      return -1;
  if (a.dataset.indexbest > b.dataset.indexbest)
      return 1;
  return 0;
}
  const SortDataBest = (value) => {
    setSortBySelected(value);
    var indexes = document.querySelectorAll("[data-indexbest]");
    var indexes1 = document.querySelectorAll("[data-indexfast]");
   // console.log(indexes, indexes1);
    var indexesArray = Array.from(indexes);
    var indexesArray1 = Array.from(indexes1);
   // console.log(indexesArray);
   // console.log(indexesArray1);
   let sorted = indexesArray.sort(comparatorBest);  
   let sorted1 = indexesArray1.sort(comparatorFast); 
  // console.log(sorted)
   sorted.forEach(e =>
    document.querySelector("#sortOrder").appendChild(e));
    sorted1.forEach(e =>
      document.querySelector("#sortOrder").appendChild(e));
  }
  const SortDataCheapest = (value) => {
    setSortBySelected(value);
    //var indexes = document.querySelectorAll("[data-indexvalue]");
    var sortableList = document.getElementById("sortOrder");
    var listItems = Array.from(sortableList.querySelectorAll(".RowResultEach"));
    listItems.sort((a, b) => {
      const aValue = parseInt(a.getAttribute("data-indexvalue"));
      const bValue = parseInt(b.getAttribute("data-indexvalue"));
    
      return aValue - bValue;
    });

    // var indexesArray = Array.from(indexes);
   // console.log(indexesArray);
  //  let sorted = indexesArray.sort(comparator);  
   // console.log(sorted)
   listItems.forEach(e =>
    document.querySelector("#sortOrder").appendChild(e));
  }
  const SortDataFastest = (value) => {
    setSortBySelected(value);
    var indexes = document.querySelectorAll("[data-indexfast]");
   // console.log(indexes);
    var indexesArray = Array.from(indexes);
   // console.log(indexesArray);
   let sorted = indexesArray.sort(comparatorFast);  
  // console.log(sorted)
   sorted.forEach(e =>
    document.querySelector("#sortOrder").appendChild(e));
  }
  // console.log("resultLegs", resultLegs.length, "resultFares",  resultFares.length);
  // console.log("resultLegs", resultLegs, "resultFares", resultFares);
  // console.log(Loader);
 
 
 
  return (
    <div>
    


      
    <div className="text-end">
    <div className="dropdown sortbyDropDown">
  <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Sort by:  {sortBySelected}
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li onClick={() => SortDataBest('Best')}><a className="dropdown-item">Best</a></li>
    <li onClick={() => SortDataCheapest('Cheapest')}><a className="dropdown-item">Cheapest</a></li>
    <li onClick={() => SortDataFastest('Fastest')}><a className="dropdown-item">Fastest</a></li>
  </ul>
  </div>
    </div>
  
 
      {
        // <React.Fragment>
        //     <Paper elevation={3}>
        //         <div className='row' >
        //             <div className='col-md-4 bgcolor'>
        //                 <h6 > Best</h6>
        //                 <h5>₹ 9,892</h5>
        //                 <small>1h 08 (average)</small>
        //             </div>
        //             <div className='col-md-4 bgcolor1'>
        //                 <h6 > Cheapest</h6>
        //                 <h5>₹ 9,892</h5>
        //                 <small>1h 08 (average)</small>
        //             </div>
        //             <div className='col-md-4 bgcolor2'>
        //                 <h6 > Fastest</h6>
        //                 <h5>₹ 9,892</h5>
        //                 <small>1h 08 (average)</small>
        //             </div>
        //         </div>
        //     </Paper>
        // </React.Fragment>
      }
      

      {triptype == "onewaytrip" ? (
        <div id="sortOrder">
          {resultTrips.map((trip, index) => {
            let data1;
            countObj = [];
            countObjFares = [];
            let durSort = [];
            let fareSort = [];

            resultLegs.map((legs, key) => {
              let data = trip.legIds[0] === legs.id;
              //let dataleg2 = trip.legIds[1] === legs.id;
             
              if (data) {
                countObj.push(data);
                durSort = legs.duration;
              //  console.log(durSort);
              }
            });
            // console.log(countObj.length)
            resultFares.map((fare, key) => {
              data1 = trip.id === fare.tripId;
              // console.log(data1, index);
              if (data1) {
                fareSort = fare.price.totalAmount;
                // console.log(data1)
                countObjFares.push(data1);
              }
            });
            //console.log(countObj, countObj.length)

            // console.log(countObjFares, countObjFares.length)

            return index < Loader ? (
              <div className="row RowResultEach mt-3 mb-3" data-indexvalue={fareSort} data-indexfast={durSort} data-indexbest={fareSort} style={{display: countObj.length === 0 || countObjFares.length === 0 ? "none" : "block" }} id={"myId" + index}  key={index}  >
                <table className="tableResult">
                  <tbody>
                    {resultLegs.sort(
                      (p1, p2) => (p1.duration > p2.duration) ? 1 : -1).map((legs, key) => {
                      // let data = trip.legIds[0] !== legs.id;
                      
                      var countAvgTotalAMT = 0;
                      var countav = 0;
                      var currencyCodeP;
                      var providerCode;
                      return (trip.legIds[0] == legs.id) ? (
                        <tr key={key}>
                          <td> <img className="airlineLogo" src={"https://pics.avs.io/150/50/" + legs.airlineCodes[0] + ".png" } ></img> </td>
                          <td> {legs.departureTime} <small style={{ fontSize: "12px", display: "block" }} > ({legs.departureDateTime.slice(0, 10)}) </small> <span className="d-block cityFrom">  {legs.departureAirportCode}  </span>  </td>
                          <td className="tableposi"> <span className="hourblock">{legs.duration}</span> -------------------- <span className="flightIcon"> <AirplanemodeActiveRoundedIcon   sx={{fontSize: "12px", flexShrink: 0,  lineHeight: 0,   color: "rgba(72, 14, 207, 0.55)"}} /> </span> <span className="stops">{legs.stopoverCode}</span>  </td> 
                          <td>{legs.arrivalTime} <small style={{ fontSize: "12px", display: "block" }}> ({legs.arrivalDateTime.slice(0, 10)}) </small>
                            <span className="d-block cityTo">
                              {legs.arrivalAirportCode}
                            </span>
                          </td>
                          <td rowSpan="2" key={key}>
                            {resultFares.sort(
                              (p1, p2) => 
                              (p1.price.totalAmount > p2.price.totalAmount) ? 1 : -1 ).map((fares, key) => {
                              if (trip.id === fares.tripId) {
                              
                                countAvgTotalAMT += fares.price.totalAmount;
                                countav++;
                                currencyCodeP = fares.price.currencyCode;
                                filteredcurrencyCodeP = CurrencySymbols.filter((x) => x.code === currencyCodeP);
                                // console.log(filteredcurrencyCodeP);
                                if(filteredcurrencyCodeP.length > 0) {
                                  currencysymbolpPassed = filteredcurrencyCodeP[0].symbol;
                                }
                               
                                
                                providerCode = fares.providerCode;
                                
                              }
                       
                                 
                              return trip.id === fares.tripId  ? (
                                <span>
                                 
                                </span>
                              ) : null;
                            })}
                            <span className="deal" id={"getattr" + index} data-tag={parseInt(countAvgTotalAMT / countav,10).toLocaleString()}> {countav > 1 ?  <span>{countav} deals from </span> :  <span> <img style={{ width: "80px" }}  src={ "https://res.cloudinary.com/wego/image/upload/c_fit,w_200,h_70/v20230108/providers/rectangular_logos/" + providerCode +".png"}/> </span> }
                            </span>
                            <span className="perHead" id="priceSort" data-source={parseInt(countAvgTotalAMT / countav,10).toLocaleString()}><span className="currencyCode"> {currencysymbolpPassed} </span> {parseInt(countAvgTotalAMT / countav,10).toLocaleString()}</span>
                           <span className="totalCost"> {currencysymbolpPassed}   {parseInt(countAvgTotalAMT / countav,10).toLocaleString()}  Total</span>
                          <Button variant="contained" className="mt-3 mb-1 clickeffect" sx={{fontSize: "14px",textTransform: "capitalize",background: "#480ecf"}} data-bs-toggle="modal" data-bs-target={"#flightDetials" + index} > View Deals
                              <ArrowForwardRoundedIcon
                                sx={{ fontSize: "16px" }}
                              />
                            </Button>
                          </td>
                        </tr>
                      ) : null;
                    })}
                  </tbody>
                </table>
                <div className="emission"></div>

                <div
                  className="modal fade"
                  id={"flightDetials" + index}
                  data-bs-keyboard="true"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">
                          Flight Details
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="row mb-4 bxShad">
                          <div className="col-md-12 flightDetailspopupRoundTrip">
                           
                            {resultLegs.map((legs, index) => {
                              let days = [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                              ];
                              let months = [
                                "Jan",
                                "Feb",
                                "March",
                                "April",
                                "May",
                                "June",
                                "July",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                              ];
                              let dateString = legs.departureDateTime;
                              let d = new Date(dateString);
                              let dayName = days[d.getDay()];
                              let dateName = d.getDate();
                              let monthName = months[d.getMonth()];
                              let fullYear = d.getFullYear();
                              let arrivalDate = legs.arrivalDateTime;
                              let a = new Date(arrivalDate);
                              let AdayName = days[a.getDay()];
                              let AdateName = a.getDate();
                              let AmonthName = months[a.getMonth()];
                              let AfullYear = a.getFullYear();

                              return trip.legIds[0] == legs.id ? (
                             <div className="col-md-12">
                             <div className="d-flex">
                             <div className="departHeading" style={{width: "50%"}}>Depart: <span className="dateTimeDepart">{dayName}, {dateName} {monthName}</span></div>
                             <div className="departHeading" style={{width: "50%", textAlign: "end"}}>Arrives: <span className="dateTimeDepart">{AdayName}, {AdateName} {AmonthName}</span></div>
                             </div>
                              <table style={{ width: "100%" }} className="tableResult">
                                <tbody>
                                  
                                      <tr>
                                        <td style={{ width: "33%", textAlign: "center" }}>
                                          <small style={{fontSize: "25px", display: "block", fontWeight: 300, marginTop: "16px", marginBottom: "5px", color: "#666666"}}>{legs.departureTime}</small>
                                          <span className="boldCode">{legs.departureAirportCode} </span> <span> {from.split(',')[0]} </span>
                                        </td>
                                        <td className="tableposi" style={{ width: "33%", textAlign: "center" }}>
                                          <span className="hourblock">{legs.duration} </span>
                                         <span className="BigDashs"></span>
                                          <span className="flightIcon"> <AirplanemodeActiveRoundedIcon sx={{ fontSize: "12px", flexShrink: 0, lineHeight: 0, color: "rgba(72, 14, 207, 0.55)" }} /> </span>
                                          <span className="stops"> {legs.stopoverCode} </span>
                                        </td>
                                        <td style={{ width: "33%", textAlign: "center" }}>
                                          <small style={{ fontSize: "25px", display: "block",  fontWeight: 300,  marginTop: "16px",  marginBottom: "5px",  color: "#666666"}} >{legs.arrivalTime}</small>
                                          <span className="boldCode">{legs.arrivalAirportCode}</span> <span> {to.split(',')[0]}</span>
                                          
                                         
                                        </td>
                                      </tr>
                                    
                                 
                                </tbody>
                              </table>
                            </div>) : null })}
                          </div>  
                            
                        </div>
                        <h5 className="modal-title">Fare options</h5>

                        {resultFares.map((fares, index) => {
                          return trip.id == fares.tripId ? (
                            <div className="faresDetailsCard" key={index}>
                              <div
                                className="row align-items-center"
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  width: "100%",
                                }}
                              >
                                <div className="col-md-6">
                                  <img
                                    style={{ width: "100px" }}
                                    src={
                                      "https://res.cloudinary.com/wego/image/upload/c_fit,w_200,h_70/v20230108/providers/rectangular_logos/" +
                                      fares.providerCode +
                                      ".png"
                                    }
                                  />
                                </div>
                                {
                                  // currency_code.data.map((currency, i) => {
                                  //   return currency.code == fares.price.currencyCode ?
                                  // : null
                                  // })
                                }
                                <div className="col-md-6 text-end">
                                  <span>
                                    <small className="currencyCode">{currencysymbolpPassed}</small>
                                    <span className="totalAmount"> {fares.price.totalAmount.toLocaleString()} </span> 
                                    <a href={fares.handoffUrl} target="_blank"  className="marginLeft" sx={{ fontSize: "14px", textTransform: "capitalize", background: "#480ecf"}}> 
                                      <Button style={{ backgroundColor: "rgb(3 13 78)", borderRadius: "0px 20px 20px 0px" }} variant="contained" className="clickeffect" > Select Deal <ArrowForwardRoundedIcon sx={{ fontSize: "16px" }} /> </Button>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null;
          })}
        </div>
      ) : (
        <div id="sortOrder">
          {resultTrips.map((trip, index) => {
            let data = [];
            let data1;
            countObj = [];
            countObjFares = [];
            let durSort = [];
            let fareSort = [];


            resultLegs.map((legs, key) => {
              data = trip.legIds[0] === legs.id;
              //let dataleg2 = trip.legIds[1] === legs.id;
              //   console.log(data);
              if (data) {
                
                countObj.push(data);
                durSort = legs.duration;
              }
            });
            // console.log(countObj.length)
            resultFares.map((fare, key) => {
              data1 = trip.id === fare.tripId;

              // console.log(data1, index);
              if (data1) {
                // console.log(data1)
                fareSort = fare.price.totalAmount;
                countObjFares.push(data1);
              }
            });
            //console.log(countObj, countObj.length)

            // console.log(countObjFares, countObjFares.length)

            return (
              <div>
                {index < Loader ? (
                  <div className="row RowResultEach checkingClass mt-3 mb-3" data-indexvalue={fareSort} data-indexfast={durSort} data-indexbest={fareSort} style={{display:countObj.length === 0 || countObjFares.length === 0 ? "none" : "block"}} id={"myId" + index} key={index} >
                    <table className="tableResult">
                      <tbody>
                        {resultLegs.map((legs, key) => {
                          // let data = trip.legIds[0] !== legs.id;
                          var countAvgTotalAMT = 0;
                          var countav = 0;
                          var currencyCodeP;
                          var providerCode;

                          return trip.legIds[0] == legs.id ? (
                            <tr className={legs.stopoverCode} key={key}>
                              <td>
                                <img className="airlineLogo" src={"https://pics.avs.io/150/50/" + legs.airlineCodes[0] + ".png"}></img>
                              </td>
                              <td>{legs.departureTime}<small style={{ fontSize: "12px", display: "block" }} >({legs.departureDateTime.slice(0, 10)})</small><span className="d-block cityFrom">{legs.departureAirportCode} </span>  </td>
                              <td className="tableposi">
                                <span className="hourblock">
                                  {legs.duration}
                                </span>
                                --------------------
                                <span className="flightIcon">
                                  <AirplanemodeActiveRoundedIcon
                                    sx={{
                                      fontSize: "12px",
                                      flexShrink: 0,
                                      lineHeight: 0,
                                      color: "rgba(72, 14, 207, 0.55)",
                                    }}
                                  />
                                </span>
                                <span className="stops">
                                  {legs.stopoverCode}
                                </span>
                              </td>
                              <td>
                                {legs.arrivalTime}
                                <small
                                  style={{ fontSize: "12px", display: "block" }}
                                >
                                  ({legs.arrivalDateTime.slice(0, 10)})
                                </small>
                                <span className="d-block cityTo">
                                  {legs.arrivalAirportCode}
                                </span>
                              </td>
                              <td
                                rowSpan="2"
                                key={key}
                                className="hiddenInMobile"
                              >
                                {resultFares.map((fares, key) => {
                                  if (trip.id === fares.tripId) {
                                    countAvgTotalAMT += fares.price.totalAmount;
                                    countav++;
                                    currencyCodeP = fares.price.currencyCode;
                                    filteredcurrencyCodeP = CurrencySymbols.filter((x) => x.code === currencyCodeP);
                                    // console.log(filteredcurrencyCodeP);
                                    if(filteredcurrencyCodeP.length > 0) {
                                      currencysymbolpPassed = filteredcurrencyCodeP[0].symbol;
                                    }
                                    providerCode = fares.providerCode;
                                  }
                                  return trip.id === fares.tripId ? (
                                    <span></span>
                                  ) : null;
                                })}

                                <span className="deal priceRangeData"  data-source={parseInt(countAvgTotalAMT / countav, 10).toLocaleString()}>
                                  {countav > 1 ? (
                                    <span>{countav} deals from </span>
                                  ) : (
                                    <span>
                                      <img
                                        style={{ width: "80px" }}
                                        src={"https://res.cloudinary.com/wego/image/upload/c_fit,w_200,h_70/v20230108/providers/rectangular_logos/" + providerCode +".png"} />
                                    </span>
                                  )}
                                </span>
                                <span className="perHead">
                                  <span className="currencyCode">
                                    {currencysymbolpPassed}
                                  </span>
                                  {parseInt(countAvgTotalAMT / countav, 10).toLocaleString()}
                                </span>
                                <span className="totalCost">
                                  {parseInt(countAvgTotalAMT / countav,10).toLocaleString()} Total
                                </span>
                                <Button
                                  variant="contained"
                                  className="mt-3 mb-1 clickeffect"
                                  sx={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                    background: "#480ecf",
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target={"#flightDetials" + index}
                                >
                                  View Deals
                                  <ArrowForwardRoundedIcon
                                    sx={{ fontSize: "16px" }}
                                  />
                                </Button>
                              </td>
                            </tr>
                          ) : null;
                        })}
                        {resultLegs.map((legs, key) => {
                          var countAvgTotalAMT = 0;
                          var countav = 0;
                          var currencyCodeP;
                          var providerCode;
                          return trip.legIds[1] == legs.id ? (
                            <tr key={key}>
                              <td>
                                <img
                                  className="airlineLogo"
                                  src={
                                    "https://pics.avs.io/150/50/" +
                                    legs.airlineCodes[0] +
                                    ".png"
                                  }
                                ></img>
                              </td>
                              <td>
                                {legs.departureTime}
                                <small
                                  style={{ fontSize: "12px", display: "block" }}
                                >
                                  ({legs.departureDateTime.slice(0, 10)})
                                </small>
                                <span className="d-block cityFrom">
                                  {legs.departureAirportCode}
                                </span>
                              </td>
                              <td className="tableposi">
                                <span className="hourblock">
                                  {legs.duration}
                                </span>
                                --------------------
                                <span className="flightIcon">
                                  <AirplanemodeActiveRoundedIcon
                                    sx={{
                                      fontSize: "12px",
                                      flexShrink: 0,
                                      lineHeight: 0,
                                      color: "rgba(72, 14, 207, 0.55)",
                                    }}
                                  />
                                </span>
                                <span className="stops">
                                  {legs.stopoverCode}
                                </span>
                              </td>
                              <td>
                                {legs.arrivalTime}
                                <small
                                  style={{ fontSize: "12px", display: "block" }}
                                >
                                  ({legs.arrivalDateTime.slice(0, 10)})
                                </small>
                                <span className="d-block cityTo">
                                  {legs.arrivalAirportCode}
                                </span>
                              </td>
                              <td key={key} className="hiddenInDesktop">
                                {resultFares.map((fares, key) => {
                                  if (trip.id === fares.tripId) {
                                    countAvgTotalAMT += fares.price.totalAmount;
                                    countav++;
                                    currencyCodeP = fares.price.currencyCode;
                                    providerCode = fares.providerCode;
                                  }
                                  return trip.id === fares.tripId ? (
                                    <span></span>
                                  ) : null;
                                })}

                                <span className="deal">
                                  {countav > 1 ? (
                                    <span>From {countav} websites </span>
                                  ) : (
                                    <span>
                                      <img
                                        style={{ width: "80px" }}
                                        src={
                                          "https://res.cloudinary.com/wego/image/upload/c_fit,w_200,h_70/v20230108/providers/rectangular_logos/" + providerCode +".png"
                                        }
                                      />
                                    </span>
                                  )}
                                </span>
                                <span className="perHead">
                                  <span className="currencyCode">
                                    {currencysymbolpPassed}
                                  </span>
                                  {parseInt(countAvgTotalAMT / countav,10).toLocaleString()}
                                </span>
                                <span className="totalCost">  {currencysymbolpPassed}  
                                  {parseInt(
                                    countAvgTotalAMT / countav,
                                    10
                                  ).toLocaleString()}
                                  Total
                                </span>
                                <Button
                                  variant="contained"
                                  className="mt-3 mb-1 clickeffect"
                                  sx={{
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                    background: "#480ecf",
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target={"#flightDetials" + index}
                                >
                                  View Deals
                                  <ArrowForwardRoundedIcon
                                    sx={{ fontSize: "16px" }}
                                  />
                                </Button>
                              </td>
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                    </table>

                    <div className="emission"></div>

                    <div
                      className="modal fade"
                      id={"flightDetials" + index}
                      data-bs-keyboard="true"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Flight Details
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="row mb-4 bxShad">
                             
                              <div className="col-md-6 flightDetailspopupTwowayTrip">
                              <h3 className="bottomLine">Outbound</h3>
                              
                                      {resultLegs.map((legs, index) => {
                                        let days = [
                                          "Sunday",
                                          "Monday",
                                          "Tuesday",
                                          "Wednesday",
                                          "Thursday",
                                          "Friday",
                                          "Saturday",
                                        ];
                                        let months = [
                                          "Jan",
                                          "Feb",
                                          "March",
                                          "April",
                                          "May",
                                          "June",
                                          "July",
                                          "Aug",
                                          "Sep",
                                          "Oct",
                                          "Nov",
                                          "Dec",
                                        ];
                                        let dateString = legs.departureDateTime;
                                        let d = new Date(dateString);
                                        let dayName = days[d.getDay()];
                                        let dateName = d.getDate();
                                        let monthName = months[d.getMonth()];
                                        let fullYear = d.getFullYear();
                                        let arrivalDate = legs.arrivalDateTime;
                                        let a = new Date(arrivalDate);
                                        let AdayName = days[a.getDay()];
                                        let AdateName = a.getDate();
                                        let AmonthName = months[a.getMonth()];
                                        let AfullYear = a.getFullYear();

                                        return trip.legIds[0] == legs.id ? (
                                            <div className="col-md-12">
                                            <div className="d-flex">
                                            <div className="departHeading" style={{width: "50%"}}>Depart: <span className="dateTimeDepart">{dayName}, {dateName} {monthName}</span></div>
                                            <div className="departHeading" style={{width: "50%", textAlign: "end"}}>Arrives: <span className="dateTimeDepart">{AdayName}, {AdateName} {AmonthName}</span></div>
                             
                                            </div>
                                  <table style={{ width: "100%" }} className="tableResult">
                                    <tbody>
                                    <tr>
                                    <td style={{ width: "33%", textAlign: "center" }}>
                                      <small style={{fontSize: "25px", display: "block", fontWeight: 300, marginTop: "16px", marginBottom: "5px", color: "#666666"}}>{legs.departureTime}</small>
                                      <span className="boldCode">{legs.departureAirportCode} </span> <span> {from.split(',')[0]} </span>
                                    </td>
                                    <td className="tableposi" style={{ width: "33%", textAlign: "center" }}>
                                      <span className="hourblock">{legs.duration} </span>
                                     <span className="BigDashs"></span>
                                      <span className="flightIcon"> <AirplanemodeActiveRoundedIcon sx={{ fontSize: "12px", flexShrink: 0, lineHeight: 0, color: "rgba(72, 14, 207, 0.55)" }} /> </span>
                                      <span className="stops"> {legs.stopoverCode} </span>
                                    </td>
                                    <td style={{ width: "33%", textAlign: "center" }}>
                                      <small style={{ fontSize: "25px", display: "block",  fontWeight: 300,  marginTop: "16px",  marginBottom: "5px",  color: "#666666"}} >{legs.arrivalTime}</small>
                                      <span className="boldCode">{legs.arrivalAirportCode}</span> <span> {to.split(',')[0]}</span>
                                      
                                     
                                    </td>
                                  </tr>
                                          </tbody>
                                  </table>
                                </div>
                                        ) : null;
                                      })}
                                    
                              </div>
                              
                              <div className="col-md-6 flightDetailspopupTwowayTrip">
                              <h3 className="bottomLine">Return</h3>
                                
                                      {resultLegs.map((legs, index) => {
                                        let days = [
                                          "Sunday",
                                          "Monday",
                                          "Tuesday",
                                          "Wednesday",
                                          "Thursday",
                                          "Friday",
                                          "Saturday",
                                        ];
                                        let months = [
                                          "Jan",
                                          "Feb",
                                          "March",
                                          "April",
                                          "May",
                                          "June",
                                          "July",
                                          "Aug",
                                          "Sep",
                                          "Oct",
                                          "Nov",
                                          "Dec",
                                        ];
                                        let dateString = legs.departureDateTime;
                                        let d = new Date(dateString);
                                        let dayName = days[d.getDay()];
                                        let dateName = d.getDate();
                                        let monthName = months[d.getMonth()];
                                        let fullYear = d.getFullYear();
                                        let arrivalDate = legs.arrivalDateTime;
                                        let a = new Date(arrivalDate);
                                        let AdayName = days[a.getDay()];
                                        let AdateName = a.getDate();
                                        let AmonthName = months[a.getMonth()];
                                        let AfullYear = a.getFullYear();

                                        return trip.legIds[1] == legs.id ? (
                                          <div className="col-md-12">
                                          <div className="d-flex">
                                          <div className="departHeading" style={{width: "50%"}}>Depart: <span className="dateTimeDepart">{dayName}, {dateName} {monthName}</span></div>
                                          <div className="departHeading" style={{width: "50%", textAlign: "end"}}>Arrives: <span className="dateTimeDepart">{AdayName}, {AdateName} {AmonthName}</span></div>
                                          </div>
                                  <table style={{ width: "100%" }} className="tableResult">
                                    <tbody>
                                    <tr>
                                    <td style={{ width: "33%", textAlign: "center" }}>
                                      <small style={{fontSize: "25px", display: "block", fontWeight: 300, marginTop: "16px", marginBottom: "5px", color: "#666666"}}>{legs.departureTime}</small>
                                      <span className="boldCode">{legs.departureAirportCode} </span> <span> {to.split(',')[0]} </span>
                                    </td>
                                    <td className="tableposi" style={{ width: "33%", textAlign: "center" }}>
                                      <span className="hourblock">{legs.duration} </span>
                                     <span className="BigDashs"></span>
                                      <span className="flightIcon"> <AirplanemodeActiveRoundedIcon sx={{ fontSize: "12px", flexShrink: 0, lineHeight: 0, color: "rgba(72, 14, 207, 0.55)" }} /> </span>
                                      <span className="stops"> {legs.stopoverCode} </span>
                                    </td>
                                    <td style={{ width: "33%", textAlign: "center" }}>
                                      <small style={{ fontSize: "25px", display: "block",  fontWeight: 300,  marginTop: "16px",  marginBottom: "5px",  color: "#666666"}} >{legs.arrivalTime}</small>
                                      <span className="boldCode">{legs.arrivalAirportCode}</span> <span>{from.split(',')[0]}  </span>
                                      
                                     
                                    </td>
                                  </tr>
                                          </tbody>
                                          </table>
                                        </div>
                                        ) : null;
                                      })}
                                   
                              </div>
                            </div>
                            <h5 className="modal-title">Fare options</h5>

                            {resultFares.map((fares, index) => {
                              return trip.id == fares.tripId ? (
                                <div className="faresDetailsCard" key={index}>
                                  <div
                                    className="row align-items-center"
                                    style={{
                                      margin: "0px",
                                      padding: "0px",
                                      width: "100%",
                                    }}
                                  >
                                    <div className="col-md-6">
                                      <img
                                        style={{ width: "100px" }}
                                        src={
                                          "https://res.cloudinary.com/wego/image/upload/c_fit,w_200,h_70/v20230108/providers/rectangular_logos/" +
                                          fares.providerCode +
                                          ".png"
                                        }
                                      />
                                    </div>
                                    {
                                      // currency_code.data.map((currency, i) => {
                                      //   return currency.code == fares.price.currencyCode ?
                                      // : null
                                      // })
                                    }
                                    <div className="col-md-6 text-end">
                                      <span> <small className="currencyCode">  {currencysymbolpPassed} </small>
                                        <span className="totalAmount"> {fares.price.totalAmount.toLocaleString()} </span>
                                        <a href={fares.handoffUrl} target="_blank" className="marginLeft" sx={{ fontSize: "14px",  textTransform: "capitalize", background: "#480ecf" }}  >
                                          <Button  style={{ backgroundColor: "rgb(3 13 78)", borderRadius: "0px 20px 20px 0px" }} variant="contained" className="clickeffect" > Select Deal <ArrowForwardRoundedIcon  sx={{ fontSize: "16px" }} /> </Button>
                                        </a>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : null;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      )}

      {resultLegs.length < 1 || resultFares.length <= 1 ? (
        <div className="noDaTa1">
          No Matched Flights please change the filters options
        </div>
      ) : null}
      {resultLegs.length > 20 || resultFares.length > 20 ? (
        <div className="text-center">
          {resultLegs.length > Loader ? (
            <Button
              variant="contained"
              className="clickeffect"
              onClick={loaderFunction}
            >
              {BoolforLoading ? <span>Show more results</span> : "Loading..."}
            </Button>
          ) : TotalResShowCheck ? (
            <span className="endMSG"> </span>
          ) : null}
        </div>
      ) : null}

      <div className="m-0"></div>
    </div>
  );
};
export default FlightsResults;
