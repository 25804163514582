import React from 'react';
import { useEffect, useState,  useContext } from 'react';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from 'react-router-dom';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import CurrencycodeContext  from './CurrencycodeContext';

 
import Airpotapi from './datacopy.json';
var axios = require('axios');



const DataInput = (props) => {
  const navigate = useNavigate();  
  let toStatus = props.toDataStatus;
  const toData = props.toData;
  let editedCurrencyCode = props.setcurrData;
    // console.log(editedCurrencyCode);
  let isregSetClick = props.isregSetClick;
 


 
   
   
  // const toData = props.toData;
  // console.log(toData)
  // const [toDataArray, settoDataArray] = useState(toData)
  // console.log(props.toDataStatus)
 
  // useEffect(() => {
      // setAPIData(APIData.name = "changed");
      // console.log(APIData);
  // }, [])

  let currentDate = new Date();

  let tomorrow = new Date()
  tomorrow.setDate(currentDate.getDate() + 7)
  let returnDate = new Date()
  returnDate.setDate(currentDate.getDate() + 14)
  let postDate = tomorrow.toISOString();
  // console.log("postDate =>", postDate)
  returnDate = returnDate.toISOString();
  // console.log("returnDate =>", returnDate)
  let currentDateISO = currentDate.toISOString();
  let defaultDate = currentDateISO.substr();
  let defaultSelectAirport;
  const [NearbyAirportVal, setNearbyAirportVal] = useState();
  const [value, setValue] = useState([postDate, returnDate]);
  const [round, setRound] = useState('roundtrip');
  const [cabin, setCabin] = useState('Economy');
  const [travellers, setTravellers] = useState('1');
  const [valuefrom, setValuefrom] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [valueTo, setValueTo] = useState([]);
  const [inputValueTo, setInputValueTo] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adult, setAdult] = useState(1);
  const [children, setChildren] = useState(0);
  const open = Boolean(anchorEl);
  const [flightapi, setFlightapi] = useState([null]);
  const [loader, setLoader] = useState(false);
  const [currencycodes, setCurrencycodes] = useState(null);
  let convertFromDate, convertToDate, IPconfig, iatacodefromslice, iatacodetoslice, config, convertonewayDate;
  const [airportDetails, setAirportDetails] = useState([]);
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [progress, setProgress] = useState(0);
  const [submittedData, setsubmittedData] = useState([]);
  const [nearbyAirportData, setnearbyAirportData] = useState([])
  const {getcurrncyCode} = useContext(CurrencycodeContext);
  const [currencyCookie, setcurrencyCookie] = useState([]);
  const [searchButtonClick, setsearchButtonClick] = useState(false)
  // console.log(isregSetClick, searchButtonClick);
  //   onload iplocation tracker for currencycodes 
  // single time run 
  const [statuscheck, setstatuscheck] = useState(true)
  let stopLooding = true;
  //const date = new Date();
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };
  const formattedDate = tomorrow.toLocaleString('en-US', options);
  
 // console.log(formattedDate);
  let ToDataArr = []
   useEffect(() => {
        if(toStatus) {
          //console.log(toData.place, toData.iata_code, toData.country)
          ToDataArr =  toData.place + "," + toData.country + ", " + toData.iata_code;
                  
           
          toStatus = false
          setValueTo(ToDataArr)
          props.ToStatusPass(toStatus)
        }
     })
    
 
  

 
  
 
const flatProps = Airpotapi.map((option) => {        
  return (option.city + ", " + option.name + ", " + option.country + ", " + option.iata_code)
})
 
  useEffect(async (props) => {
    const Iplocation = await axios.get("https://api.ipgeolocation.io/ipgeo?apiKey=3c12e3aead874277893299ff7e55ff9e");
    //  console.log(Iplocation.data);
    // Iplocation = Iplocation.data;
      // console.log(Iplocation);

    let cityName = Iplocation.data.city;
    let countyName = Iplocation.data.country_name;
    let countyCode2 = Iplocation.data.country_code2;
 
   const cityArray = cityName.split(' ');
  //  console.log(cityArray); // Output: ['Navi', 'Mumbai']
   // console.log(Iplocation.data.currency.code)
    // setCurrencycodes(Iplocation.data.currency.code);
    getcurrncyCode(Iplocation);
    // console.log(Airpotapi);
    let NearbyIATACode = Airpotapi.filter(item => {
     return cityArray.some(keyword => item.name.includes(keyword) || item.city.includes(keyword))
     });
    //  console.log(NearbyIATACode);
 
    // console.log(getcurrncyCode);
      // console.log(currencycodes);
    //   setLat(Iplocation.latitude);
    //   setLng(Iplocation.longitude);
    // const magicKeyFetch = await axios.get("https://api.flightapi.io/place/654033b6126c0d94fd87c7df/" + countyCode2 + "/" + cityName + "");
    
    // console.log(magicKeyFetch);
    // let cityNearbyMagicKey = magicKeyFetch.data.suggestions[2].magicKey;
    //  console.log(cityNearbyMagicKey);
    // const NearbyAirport = await axios.get("https://api.flightapi.io/nearby/654033b6126c0d94fd87c7df?country=" + countyName + "&token=" + cityNearbyMagicKey + "");
    // console.log(NearbyAirport.data);
    // let nByData = NearbyAirport.data;
    // let NearbyAir = "Nearby Airports";
    // let NearbyAirportResult = nByData[NearbyAir];
    //console.log(NearbyAirportResult)
    let NearbyAirportResult = NearbyIATACode;
    setnearbyAirportData(NearbyIATACode);
    // if(NearbyAirportResult.length >= 1) {
    //   props.getnearbyairport(NearbyAirportResult)
    // }
   
    // removeSpace.split("");
    // setNearbyAirport()
    
    defaultSelectAirport = NearbyAirportResult[0];
    //console.log(defaultSelectAirport);
    defaultSelectAirport = defaultSelectAirport.city + ", "+ defaultSelectAirport.name +", "+ defaultSelectAirport.country +", "+defaultSelectAirport.iata_code;
    setValuefrom(defaultSelectAirport);
   
      //console.log(valuefrom);
    
    
   
    // console.log(NearbyAirportResult[0].Code);
  },[])
 
  // console.log(NearbyAirportVal);  
  
  //   IATA code 
 
 
  useEffect(()=> {
    if(editedCurrencyCode) {
      if(editedCurrencyCode.length > 0) {
        editedCurrencyCode = JSON.parse(editedCurrencyCode);
        editedCurrencyCode[1] = editedCurrencyCode[1].slice(0, editedCurrencyCode[1].indexOf(' '))
         
        // console.log(editedCurrencyCode[1]);
        setCurrencycodes(editedCurrencyCode[1]);
      }
     
    }
  }, [editedCurrencyCode])
 
    //   click handler function

  const handleAdultPlus = (e) => {
    setAdult(prevCount => prevCount + 1);
  }
  // adult count less
  const handleAdultLess = (e) => {
    setAdult(prevCount => prevCount - 1);
  }

  // children count plus
  const handleChildrenPlus = (e) => {
    setChildren(prevCount => prevCount + 1);
    // console.log(adultLess);
  }

  // children count less
  const handleChildrenLess = (e) => {
    setChildren(prevCount => prevCount - 1);
  }

  // open onlick event for traveller
  const handleClick = (event) => {

    setAnchorEl(event.currentTarget);

  };

  // close onlick event for traveller
  const handleClose = () => {
    // console.log(value)
    setAnchorEl(null);
  };


  const handleChangeRound = (event) => {
    setRound(event.target.value);
    // console.log(event.target.value)
    if(event.target.value === "onewaytrip") {
      // console.log(formattedDate);
      setValue(formattedDate);
    } else {
      setValue([postDate, returnDate]);
    }
     
    
   //console.log(value)

  };
  //console.log(value);
  const handleChangeCabin = (event) => {
    setCabin(event.target.value);

  };

  useEffect(()=> {
   
      if((searchButtonClick === true) && (isregSetClick === true)) {
        getData();
        // if(searchButtonClick === true) {
        //   setsearchButtonClick(false);
        // } else if(isregSetClick === true) {
        //   isregSetClick = false;
        // }
      }
    
  },[currencycodes]);

  // let listing = (props, option, index) => (
  //   <Box component="li" key={index} {...props}>
  //     <div className="boxRow">
  //       <div className='iataCol'>
  //         {option.iata_code}
  //       </div>
  //       <div className='cityName'> {option.city}, {option.country} <br /> <span style={{ fontSize: "12px" }}>{option.name}</span> </div>
  //     </div>
  //   </Box>
  // )

  let boolean = false;
  
  const getData = () => {
    
    try {
      // console.log(currencycodes);
        // console.log("Yes It woks");
      //console.log(valuefrom)
      //console.log(valueTo)
      // iatacode();
      // setLoader(true);

      //  const getLoader = ()=>{
      //   loader = document.getElementById('loaderStyle');
      //   loader.show();
      //  }
      //  getLoader()
    
       
      // console.log(airportDetails)
      // console.log(currencycodes)
      // console.log(round)
      // console.log(cabin)
      // console.log(adult)
      // console.log(children)
      // console.log(valuefrom)
      // console.log(valueTo)
      // let defaultValueFrom = valuefrom.[1];
      // console.log(valuefrom)
      let onewayDate = value;
      // iatacodefromslice = valuefrom.iata_code || valuefrom[1];
      if(valuefrom === "" || valuefrom === null || valuefrom === undefined || valuefrom.length === 0) {
        alert("Please select the From location");
        return false;
      }else {
        iatacodefromslice = valuefrom.slice(-3);
      }
      if(valueTo === "" || valueTo === null || valueTo === undefined || valueTo.length === 0) {
        alert("Please select the To location");
        return false;
      }else {
        iatacodetoslice = valueTo.slice(-3);
      }
      let fromDate = value[0];
      // console.log(fromDate);
    let toDate = value[1];
      // console.log(toDate);
      if(toDate === null) {
        alert("Please select the travel date");
        return false;
      }
      setsearchButtonClick(true);
      props.onCollectLoader(true);
      
      //console.log(iatacodefromslice)
     // iatacodetoslice = valueTo.slice(-3);
        // console.log(iatacodetoslice)
      // console.log(iatacodetoslice)
     
       


      function convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
      convertonewayDate = convert(value)
      convertFromDate = convert(fromDate);
      convertToDate = convert(toDate);
      // console.log(convert(value))
      // console.log(convert(fromDate))
      // console.log(convert(toDate))
      //-> "2011-06-08"
 
      boolean = true;
      const previewData = {
        "triptype": round,
        "travellercount": {
          "adult": adult,
          "children": children
        },
        "cabintype": cabin,
        "from": valuefrom, 
        "to": valueTo,
        "departtime": {
          convertFromDate,
          convertonewayDate
        },
        "returntime": convertToDate
      };
      const queryString = new URLSearchParams(previewData).toString();
      // console.log(queryString);
      const url = `/search?flt=${queryString}`;
      window.history.pushState(null, null, url);
     // navigate.push(`/${queryString}`, { replace: true });


      props.onSaveSubmittedData(previewData, true);

      //    hide and show function To do
      let fseSearch_h1 = document.querySelector('.fseSearch h1');
      let fseheader = document.querySelector('.fseheader');
      let fselogo_a = document.querySelector('.fselogo a');
      let fseSearch = document.querySelector('.fseSearch');
      let searchFlights = document.querySelector('.searchFlights');
      let tipsInformation = document.querySelector('.tipsInformation')
      let searchYahoo = document.getElementById('searchYahoo');
      fseSearch_h1.style.marginTop = '0px';
      fseSearch_h1.style.marginBottom = '0px';
      fseSearch.style.display = "none";
      fseSearch_h1.style.display = 'none';
      fseheader.style.backgroundColor = '#ffffff';
      fseheader.style.borderBottom = '1px solid #e6e6e6';
      //fseheader.style.boxShadow = 'rgb(0 0 0 / 16%) 0px 1px 4px';
      fseheader.style.position = 'relative';
      fseSearch.style.height = 'auto';
      fseSearch.style.minHeight = 'auto';
      fseSearch.style.backgroundImage = 'linear-gradient(#fff,#F5F5FA)';
      fselogo_a.style.color = '#000000';
     // searchFlights.style.backgroundImage = 'linear-gradient(90deg,rgb(242 236 255) 0,rgb(242 236 255) 100%)';
     searchFlights.style.backgroundColor = '#ffffff';
      tipsInformation.style.display = 'none';
      searchYahoo.style.border = '2px solid #595e83';
      searchYahoo.style.background = '#ffffff';
      searchYahoo.style.boxShadow = 'none';
      searchYahoo.value = '';



      //    console.log(previewData);
      //    setsubmittedData(previewData)
      //  console.log(submittedData);
         
 

      {
        round === "roundtrip" ?
        config = {
          method: 'get',
          //  url: '/twowayTripNew.json',  
            url: 'https://api.flightapi.io/' + round + '/654033b6126c0d94fd87c7df/' + iatacodefromslice + '/' + iatacodetoslice + '/' + convertFromDate + '/' + convertToDate + '/' + adult + '/' + children + '/0/' + cabin + '/' + currencycodes + '',
          headers: {}
        } : config = {
          method: 'get', 
            url: 'https://api.flightapi.io/' + round + '/654033b6126c0d94fd87c7df/' + iatacodefromslice + '/' + iatacodetoslice + '/' + convertonewayDate + '/' + adult + '/' + children + '/0/' + cabin + '/' + currencycodes + '',
          // url: '/onewaytripsample_copy.json',    
          headers: {}
        }

      }   


      axios(config)
        .then(function (response) {
         // console.log(response);
          ///setFlightapi(response.data);
          if(response.status === 200){
            props.onSaveFlightApiData(response.data, true, 'Loading');
            
          }
          
          // let resonseData = JSON.stringify(response.data);
          // let changedData = {
          //   data: response.data
          // }
          // setAPIData(Object.assign(APIData, changedData));
          // console.log(APIData);
          
          // console.log(JSON.stringify(response.data));

        })
        .catch(function (error) {
          console.log(error);
          props.errorName(error.message, true)
        });



    } catch (err) {
      console.log(err)
      props.onValidate(err, true);
    }
  };
  // const {currencycodes} = useContext(CurrencycodeContext);
  
  return (
    <div>

      


        <div className='searchFlights'>
          <div className='RoundCabin'>
            <FormControl>
              <InputLabel id="round">Round</InputLabel>
              <Select className='noborder'
                labelId="round"
                id="round"
                value={round}
                label="Round"
                onChange={handleChangeRound}
              >
                <MenuItem value='roundtrip'>Round trip</MenuItem>
                <MenuItem value='onewaytrip'>One-way</MenuItem>

              </Select>
            </FormControl>


            <FormControl>
              <span id="traveller">Traveller</span>

              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <PersonOutlineIcon className="text-secondary" />
                <span className="text-dark">{adult + children}</span> <KeyboardArrowDownIcon className="text-secondary" />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div className='pe-2 ps-2 fs-8  flexsys'>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <span className='travellerName'>Adult</span>
                        </td>
                        <td>
                          {adult >= 1 ? <span className='count'><Button className="p-1" onClick={handleAdultLess}> - </Button><span>{adult} </span><Button className="p-1" onClick={handleAdultPlus}> + </Button></span> : <span className='count'><Button className="p-1" disabled onClick={handleAdultLess}> - </Button><span>{adult} </span><Button className="p-1" onClick={handleAdultPlus}> + </Button></span>}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className='travellerName'>Children</span>
                        </td>
                        <td>
                          {children >= 1 ? <span className='count'><Button className="p-1" onClick={handleChildrenLess}> - </Button><span>{children} </span><Button className="p-1" onClick={handleChildrenPlus}> + </Button></span> : <span className='count'><Button className="p-1" disabled onClick={handleChildrenLess}> - </Button><span>{children} </span><Button className="p-1" onClick={handleChildrenPlus}> + </Button></span>}

                        </td>
                      </tr>
                    </tbody>
                  </table> </div>

              </Menu>
            </FormControl>
            <FormControl>
              <InputLabel id="cabin">Cabin</InputLabel>
              <Select className='noborder'
                labelId="cabin"
                id="cabin"
                value={cabin}
                label="Cabin"
                onChange={handleChangeCabin}
              >
                <MenuItem value='Economy'>Economy</MenuItem>
                <MenuItem value='Premium_Economy'>Premium Economy</MenuItem>
                <MenuItem value='Business'>Business</MenuItem>
                <MenuItem value='First'>First</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className='SearchInputs'>
              
          <FormControl>
           <Autocomplete
             options={flatProps}
              value={valuefrom}
              onChange={(event, newValue) => { setValuefrom(newValue); }}
               inputValue={inputValue}
               onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              // defaultValue={valuefrom[0]}
              id="from"
               renderInput={(params) => (
                <TextField
                {...params}
                label="From"
                />
              )}
            />
            </FormControl>
            <FormControl>
            <Autocomplete
             options={flatProps}
             value={valueTo}
              onChange={(event, newValueTo) => {
                setValueTo(newValueTo);
              }}
              inputvalueto={inputValueTo}
              onInputChange={(event, newInputValueTo) => {
                setInputValueTo(newInputValueTo);
              }}
              id="to"
            //  defaultValue={valueTo}
               
            //   getOptionLabel={(option) => (option.city + ", " + option.name + ", " + option.country)}
             

            // renderInput={(params) => (
            //   <TextField {...params} label="readOnly" variant="standard" />
            //  )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="To"
                
              />
            )}
            /> 
            </FormControl>
            {round === "onewaytrip" ?
              <LocalizationProvider dateAdapter={AdapterDateFns} >
                <DatePicker
                  className="onewaytripDatePicker" 
                  minDate={new Date(defaultDate)}
                  label="Depart"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}

                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              : <LocalizationProvider dateAdapter={AdapterDateFns} id="roundtripDate">
                <DateRangePicker
                  minDate={new Date(defaultDate)}
                  startText="Depart"
                  endText="Return"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}

                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />

                      <TextField id="" {...endProps} />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>}

          </div>


          <div className='searchButton'>

            <Button type='submit' onClick={getData} variant="contained"  className='searchButtonInside'><SearchSharpIcon className='searchIcon' />   Search </Button>

          </div>


        </div>
      




      

    </div>
  )
}

export default DataInput
