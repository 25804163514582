import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LoginContext from './LoginContext';
import PublicIcon from '@mui/icons-material/Public';
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import {db} from "./firebase-config";
import { addDoc, collection } from 'firebase/firestore';
import axios from 'axios';
 

const Header = () => { 
  
  const [searchDate, setSearchDate] = useState();
  const searchCollectionRef = collection(db, "yrr-wundrfly");


  const { item } = useContext(LoginContext);
  const { getLoginId } = useContext(LoginContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setmenuOpen] = useState(false);
  const open = Boolean(anchorEl);
  const [searchValue, setSearchValue] = useState();
  const [items, setItems] = useState([]);
  const [loginData, setLoginData] = useState(
    localStorage.getItem('loginData') ? JSON.parse(localStorage.getItem('loginData')) 
    : null)
    const [countryFlag, setCountryFlag] = useState(null);
    let Iplocationflag, IPconfigflag;
    const navigate = useNavigate();
     
  //   // ipgealocation for flag
  // const ipLocationflag = () => {
  //   IPconfigflag = {
  //     method: 'get',
  //     url: 'https://api.ipgeolocation.io/ipgeo?apiKey=3c12e3aead874277893299ff7e55ff9e',
  //     headers: {}
  //   };

  //   axios(IPconfigflag)
  //     .then(function (currency) {
  //       // console.log(currency.data);
  //       Iplocationflag = currency.data;
  //       console.log(Iplocationflag);
  //       setCountryFlag(Iplocationflag.country_flag)
        
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }
  // useEffect(() => {
  //   ipLocationflag();
  // }, [])
 const handleLogin = async(googleData) => {
   // console.log(googleData.profileObj.imageUrl);
   
  const res = await fetch('/api/google-login',  {
    method: 'POST',
    body: JSON.stringify({
    token: googleData.tokenId,
    }),
    headers: {
      'content-Type' : 'application/json',
    },
  });

  const data = await res.json();
  setLoginData(data);
  localStorage.setItem('loginData', JSON.stringify(data));
   // console.log(loginData);
   getLoginId(data);
 }
 const handleLogout = () => {
  localStorage.removeItem('loginData');
  setLoginData(null);
  getLoginId(null);
 }
 const handleFailure = (result) => {
  // console.log("Login has been Failured! Please try again");
 }
 const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};
const SearchYahoo = (event) => {
  
//  console.log(event);
   if(event) {
    if(event.key === 'Enter') {
      // console.log("enter works");
      let searchYahoo = document.getElementById('searchYahoo');
      // console.log(searchYahoo.value);
       let SYvalue = searchYahoo.value;
       let SearchedDate = new Date();
       postSearch(SYvalue, SearchedDate);
      //  alert("Enter");
      
       
      // navigate('/search?q='+ SYvalue);
       // console.log(queryString);
       const url = `/search?q=${SYvalue}`;
        window.history.pushState(null, null, url);
        let passLink = 'http://yssads.ddc.com/yhs.php?c=23572&surl=https://yrr.wundrfly.com&kw='+ SYvalue;
      // console.log(passLink);
       setTimeout(() => {
        //  navigate(passLink, { replace: true });
         window.open(passLink, '_target', 'noreferrer');
       }, 500)
       
       
     }
   }else {
    // let searchYahoo = document.getElementById('searchYahoo');
    // // console.log(searchYahoo.value);
    //  let SYvalue = searchYahoo.value;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const qValue = urlParams.get('q');
    let SearchedDate = new Date();
    postSearch(qValue, SearchedDate);
     navigate('/search?q='+ qValue);
     // console.log(queryString);
     const url = `/search?q=${qValue}`;
      window.history.pushState(null, null, url);
      let passLink = 'http://yssads.ddc.com/yhs.php?c=23572&surl=https://yrr.wundrfly.com&kw='+ qValue;
    // console.log(passLink);
     setTimeout(() => {
      //  navigate(passLink, { replace: true });
       window.open(passLink, '_self', 'noreferrer');
     }, 500)
  }
   
  
}
const mobileMenu = (type) => {
     console.log(type);
     if(type === 'open') {
      setmenuOpen(true);
     } else {
      setmenuOpen(false);
     }
}
useEffect(()=> {
  if(loginData) {
    getLoginId(loginData);
  }

})

useEffect(()=> {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const qValue = urlParams.get('q');
  setSearchValue(qValue);
  console.log(qValue);
  if(qValue) {
    SearchYahoo();
  }
}, [])
const enteredSearch = (e) => {
  setSearchValue(e.target.value);
  console.log(e.target.value);
}
const postSearch = async(value, date) => {
  console.log(value, date);

  // await addDoc(searchCollectionRef, {
  //  terms:  value,
  //  Date: date
  // });
  
  try{
    const response = await axios.post('https://server.taskticks.com/api/items/yrrWundrfly', {
    terms:  value,
    Date: date
    });
    setItems([...items, response.data]);
  } catch (error) {
    console.error(error);
  }
}
return(
    <div className='fseheader'>
    <div className='container'>
      <div className='row mobileRowIrrtn'>
        <div className='col-md-4'>
          <div className='fselogo'>
          <Link to='/'> <img className='logoMain' src='/logo4.png' /></Link>
          <span className='mobCurr'>
          <PublicIcon className='cuurSelect' data-bs-toggle="modal" data-bs-target="#Currency"/>
          {
            menuOpen ? 
            <span> <CloseSharpIcon className='menuCloseList' onClick={() => mobileMenu('close')} sx={{color: '#030d4e', fontSize: '30px'}}></CloseSharpIcon>
            <div className='MenuMobileList'>
                <Link className='headerMenu' to="/about-us">About us</Link>
                <a className='headerMenu' href="https://wundrfly.com/blog">Blog</a>
            </div>
            </span> : <MenuSharpIcon className='menuOpenList' onClick={() => mobileMenu('open')} sx={{color: '#030d4e', fontSize: '30px'}}></MenuSharpIcon>
          }
          
          
          
          </span>
          
          </div>
          
        </div>
        <div className='col-md-8 d-flex justify-content-end align-items-center'>
         
        <input onKeyDown={SearchYahoo} type='search' id='searchYahoo' onChange={enteredSearch} value={searchValue} placeholder='Search the web' /> 
         
        <div className='onlyViewInDesktop'>
        <span className='DesktopCurr'><PublicIcon className='cuurSelect ' data-bs-toggle="modal" data-bs-target="#Currency"/></span>                 
        <Link className='headerMenu' to="/about-us">About us</Link>
        <a className='headerMenu' href="https://wundrfly.com/blog">Blog</a>
        </div>
          <div className='fselogin' style={{display: 'none'}}>
            {
              loginData ? (

                <div className='loginData'>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
          <IconButton className="p-0"
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          ><Avatar className="picADjust" sx={{ width: 40, height: 40 }}  alt='profilePicture' src={loginData.picture}></Avatar>
            
          </IconButton>
        </Tooltip>
      </Box>
      <Menu className='p-0'
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 300,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#5c26da',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
      <div className='picBG'>
      <img  alt='profilePicture' src={loginData.picture}/>
      </div>
      
        <Typography  className='loginDataName'>
           {loginData.name} 
          
        </Typography>
        <Typography className="text-center mb-3" sx={{fontSize: '12px',  flexShrink: 0  }}>
       {loginData.email} 
        </Typography>
        <Divider />
        <Link to="/my-account"> 
        <MenuItem className="mt-2"  sx={{fontSize: '14px',  flexShrink: 0 , padding: '10px 15px' }}>
        <ListItemIcon><AccountCircleOutlinedIcon className='primary-color' fontSize="small"/></ListItemIcon> My account
        </MenuItem>
        </Link>
         <MenuItem onClick={handleLogout} sx={{ fontSize: '14px', flexShrink: 0, padding: '10px 15px'  }}>
          <ListItemIcon>
            <Logout className='primary-color'  fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
                
                 
                </div>
              ) : (
                
                <GoogleLogin clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID} 
                
                onSuccess={handleLogin} 
                onFailure={handleFailure} 
                cookiePolicy={'single_host_origin'}>
                <Button variant="outlined">Sign in</Button>
                </GoogleLogin>
                
               
              )
            }
          </div>
          

        </div>
      </div>
    </div>
 
  </div>
)



}
export default Header;